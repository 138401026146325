import "../scss/index.scss";

const navMobileIco = document.querySelector('.t-top-navmobile');
const mobileMenuClose = document.querySelector('.t-mobilemenu-close');
const mobileMenu = document.querySelector('.t-mobilemenu');
setTimeout(function () {
  mobileMenu.style.display = 'flex'
}, 100)

if (navMobileIco && mobileMenu) {
  navMobileIco.addEventListener('click', function () {
    mobileMenu.classList.add('t-mobilemenu--active');
    document.body.classList.add('t-layout-unscrollable');
  })
  mobileMenuClose.addEventListener('click', function () {
    mobileMenu.classList.remove('t-mobilemenu--active');
    document.body.classList.remove('t-layout-unscrollable');
  })
}
